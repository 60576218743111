import { Modal as MantineModal } from '@mantine/core';

import styles from './Modal.module.css';

export const Modal = MantineModal.extend({
  classNames: styles,
  vars: (theme, props) => {
    return {
      root: {
        '--modal-radius': props.size === 'sm' ? 'var(--mantine-radius-sm)' : 'var(--mantine-radius-md)',
      },
    };
  },
  defaultProps: {
    size: 'sm',
  },
});
