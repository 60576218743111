import { SVGWrapper, SVGWrapperProps } from './SvgWrapper';
import { getIconSize } from './utils';

export const CpuChip = (props: SVGWrapperProps): JSX.Element => {
  const iconSize = getIconSize(props.size || 'sm');
  return (
    <SVGWrapper {...props}>
      <g clipPath="url(#clip0_39_26937)">
        <path d="M5.24984 1.16666V2.33332M8.74984 1.16666V2.33332M5.24984 11.6667V12.8333M8.74984 11.6667V12.8333M11.6665 5.24999H12.8332M11.6665 8.16666H12.8332M1.1665 5.24999H2.33317M1.1665 8.16666H2.33317M5.13317 11.6667H8.8665C9.8466 11.6667 10.3366 11.6667 10.711 11.4759C11.0403 11.3081 11.308 11.0404 11.4758 10.7111C11.6665 10.3368 11.6665 9.84675 11.6665 8.86666V5.13332C11.6665 4.15323 11.6665 3.66319 11.4758 3.28884C11.308 2.95956 11.0403 2.69184 10.711 2.52406C10.3366 2.33332 9.8466 2.33332 8.8665 2.33332H5.13317C4.15308 2.33332 3.66303 2.33332 3.28869 2.52406C2.9594 2.69184 2.69169 2.95956 2.52391 3.28884C2.33317 3.66319 2.33317 4.15323 2.33317 5.13332V8.86666C2.33317 9.84675 2.33317 10.3368 2.52391 10.7111C2.69169 11.0404 2.9594 11.3081 3.28869 11.4759C3.66303 11.6667 4.15308 11.6667 5.13317 11.6667ZM6.18317 8.74999H7.8165C8.1432 8.74999 8.30655 8.74999 8.43133 8.68641C8.54109 8.63048 8.63033 8.54125 8.68626 8.43148C8.74984 8.3067 8.74984 8.14335 8.74984 7.81666V6.18332C8.74984 5.85663 8.74984 5.69328 8.68626 5.5685C8.63033 5.45873 8.54109 5.3695 8.43133 5.31357C8.30655 5.24999 8.1432 5.24999 7.8165 5.24999H6.18317C5.85647 5.24999 5.69312 5.24999 5.56834 5.31357C5.45858 5.3695 5.36934 5.45873 5.31342 5.5685C5.24984 5.69328 5.24984 5.85663 5.24984 6.18332V7.81666C5.24984 8.14335 5.24984 8.3067 5.31342 8.43148C5.36934 8.54125 5.45858 8.63048 5.56834 8.68641C5.69312 8.74999 5.85647 8.74999 6.18317 8.74999Z" />
      </g>
      <defs>
        <clipPath id="clip0_39_26937">
          <rect width={iconSize} height={iconSize} fill="white" />
        </clipPath>
      </defs>
    </SVGWrapper>
  );
};
