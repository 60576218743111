import { Menu as MantineMenu } from '@mantine/core';
import styles from './Menu.module.css';

export const Menu = MantineMenu.extend({
  classNames: () => {
    return {
      divider: styles.divider,
      dropdown: styles.dropdown,
      arrow: styles.arrow,
      item: styles.item,
    };
  },
});
