import { Combobox as MantineCombobox } from '@mantine/core';

import styles from './Combobox.module.css';

export const Combobox = MantineCombobox.extend({
  classNames: {
    dropdown: styles.dropdown,
    options: styles.options,
    search: styles.search,
    option: styles.option,
    groupLabel: styles.groupLabel,
  },
  defaultProps: {
    withArrow: false,
    size: 'sm',
    offset: 4,
  },
});
