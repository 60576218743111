import { Table as MantineTable } from '@mantine/core';
import styles from './Table.module.css';

export const Table = MantineTable.extend({
  vars: () => {
    return {
      table: {
        '--table-highlight-on-hover-color': 'var(--mantine-color-gray-0)',
      },
    };
  },
  classNames: {
    thead: styles.tableHeader,
    th: styles.tableHeaderCell,
    tr: styles.tableRow,
  },
  defaultProps: {
    highlightOnHover: true,
  },
});
