import * as Stitches from '@stitches/react';
export type { VariantProps } from '@stitches/react';

export const { styled, keyframes, css, config, getCssText } = Stitches.createStitches({
  theme: {
    colors: {
      // Semantic names from things chosen from our color palette.
      // NOTE: This hardcodes the referenced color because using the symbolic name breaks in some circumstances.
      primary: '#00A2E9', // $accent6
      primaryQuiet: '#B8E9FF', // $accent2
      primaryWithAlpha: '#00A2E91E', // 12%
      hoverBackgroundGray: '#F3F5F6', // $gray1
      hoverForegroundGray: '#4E6169', // $gray7

      // A couple one-off colors that don't come from our palette. These are bad and should feel bad.
      offWhite: '#FAFCFF',
      loginScreenDark: '#1C1E2A',

      // Our fancy blue. Use $primary as the default highlight color.
      accent1: '#EBF9FF',
      accent2: '#B8E9FF',
      accent3: '#85DAFF',
      accent4: '#52CAFF',
      accent5: '#1FBBFF',
      accent6: '#00A2E9',
      accent7: '#0080B8',
      accent8: '#005C85',
      accent9: '#003952',
      accent10: '#00151f',

      // Our standard boring gray.
      gray0_5: '#F9FAFB',
      gray1: '#F3F5F6',
      gray1_5: '#E5E9EC',
      gray2: '#D6DDE1',
      gray3: '#B9C5CB',
      gray4: '#9CADB5',
      gray5: '#7E959F',
      gray6: '#647C86',
      gray7: '#4E6169',
      gray8: '#39464C',
      gray9: '#232B2F',
      gray10: '#0d1012',

      // Our custom purple.
      secondary1: '#F6EBFF',
      secondary2: '#DEB8FF',
      secondary3: '#C685FF',
      secondary4: '#AF52FF',
      secondary5: '#971FFF',
      secondary6: '#7D00E9',
      secondary7: '#6300B8',
      secondary8: '#470085',
      secondary9: '#2C0052',
      secondary10: '#10001F',

      // Other standard colors! Stick to these please.
      wsGreenDark: '#00522F',
      wsGreen: '#00D67C',
      wsGreenLight: '#85FFCC',
      wsGreenVeryLight: '#F2FDF8', // wsGreen at 5% on a white background.
      wsRedDark: '#52001D',
      wsRed: '#EB0054',
      wsRedLight: '#FF85B0',
      wsRedVeryLight: '#FFE6EF',
      wsOrangeDark: '#523B00',
      wsOrange: '#EBA900',
      wsOrangeLight: '#F5D484',
      wsOrangeVeryLight: '#FDFAF1',
    },
    space: {
      // Add more semantically-named dimensions to share here.
      cardPadding: '16px',
      internalGridGap: '12px', // Typical spacing for grids inside of cards
      externalGridGap: '24px', // Typical spacing for grids between cards
    },
    sizes: {
      sidebarWidth: '210px',
      syncSelectorWidth: '127px',
    },
    fontSizes: {
      h1: '32px',
      h2: '24px',
      h3: '18px',
      h4: '16px',
      h5: '14px',
      bodySmall: '12px',
      bodyMedium: '14px',
      bodyLarge: '16px',
      inlineBadge: '8px',
    },
    fontWeights: {
      // NOTE! If you are changing these, also adjust _document.tsx, which loads the sizes from google fonts.
      light: '300',
      medium: '450',
      semiBold: '550',
      bold: '650',
    },
    radii: {
      round: '50%',
      pill: '9999px',
      regular: '8px',
    },
    shadows: {
      wsDropShadow: 'hsl(206 22% 7% / 35%) 0px 10px 38px -10px, hsl(206 22% 7% / 20%) 0px 10px 20px -15px',
    },
    transitions: {
      wsHoverTransition: '200ms ease-out',
    },
    zIndices: {
      normal: 1,
      detailDrawer: 10,
      dialogSkrim: 20,
      dialogContent: 21,
      selectPopout: 22, // Above dialogContent.
      toasts: 2147483647,
    },
  },
  media: {
    oneColumnSigninPage: '(max-width: 850px)',
  },
  utils: {},
});
export type CSS = Stitches.CSS<typeof config>;
