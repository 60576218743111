import { SVGWrapper, SVGWrapperProps } from '@/components/v2/Icons/SvgWrapper';
import { getIconSize } from '@/components/v2/Icons/utils';

export const UserCircle = (props: SVGWrapperProps): JSX.Element => {
  const iconSize = getIconSize(props?.size || 'sm');
  return (
    <SVGWrapper {...props}>
      <g clipPath="url(#clip0_39_26919)">
        <path d="M3.10101 11.3391C3.45587 10.5031 4.28437 9.91669 5.24984 9.91669H8.74984C9.7153 9.91669 10.5438 10.5031 10.8987 11.3391M9.33317 5.54169C9.33317 6.83035 8.2885 7.87502 6.99984 7.87502C5.71117 7.87502 4.6665 6.83035 4.6665 5.54169C4.6665 4.25302 5.71117 3.20835 6.99984 3.20835C8.2885 3.20835 9.33317 4.25302 9.33317 5.54169ZM12.8332 7.00002C12.8332 10.2217 10.2215 12.8334 6.99984 12.8334C3.77818 12.8334 1.1665 10.2217 1.1665 7.00002C1.1665 3.77836 3.77818 1.16669 6.99984 1.16669C10.2215 1.16669 12.8332 3.77836 12.8332 7.00002Z" />
      </g>
      <defs>
        <clipPath id="clip0_39_26919">
          <rect width={iconSize} height={iconSize} fill="white" />
        </clipPath>
      </defs>
    </SVGWrapper>
  );
};
