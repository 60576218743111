import { SVGWrapper, SVGWrapperProps } from '@/components/v2/Icons/SvgWrapper';
import { getIconSize } from '@/components/v2/Icons/utils';

export const MagicWand = (props: SVGWrapperProps): JSX.Element => {
  const iconSize = getIconSize(props.size || 'sm');
  return (
    <SVGWrapper {...props}>
      <g clipPath="url(#clip0_39_26308)">
        <path d="M7.58325 8.16663L5.83325 6.41663M8.75595 2.04163V1.16663M11.0539 2.95201L11.6726 2.33329M11.0539 7.58329L11.6726 8.20201M6.42262 2.95201L5.8039 2.33329M11.9643 5.24996H12.8393M3.57655 12.1733L8.96495 6.78493C9.19596 6.55392 9.31146 6.43841 9.35474 6.30522C9.3928 6.18806 9.3928 6.06186 9.35474 5.9447C9.31146 5.81151 9.19596 5.696 8.96495 5.46499L8.53488 5.03493C8.30387 4.80392 8.18836 4.68841 8.05517 4.64513C7.93801 4.60707 7.81181 4.60707 7.69465 4.64513C7.56146 4.68841 7.44595 4.80392 7.21494 5.03493L1.82654 10.4233C1.59554 10.6543 1.48003 10.7698 1.43675 10.903C1.39869 11.0202 1.39869 11.1464 1.43675 11.2636C1.48003 11.3967 1.59554 11.5122 1.82655 11.7433L2.25661 12.1733C2.48762 12.4043 2.60313 12.5198 2.73632 12.5631C2.85348 12.6012 2.97968 12.6012 3.09684 12.5631C3.23003 12.5198 3.34554 12.4043 3.57655 12.1733Z" />
      </g>
      <defs>
        <clipPath id="clip0_39_26308">
          <rect width={iconSize} height={iconSize} fill="white" />
        </clipPath>
      </defs>
    </SVGWrapper>
  );
};
