import Bugsnag from '@bugsnag/js';
import TagManager from 'react-gtm-module';
import { getBuildFlavor } from '../../utils/flags';
import { isNotEmpty } from '../../utils/helpers';

/**
 * Function to initialize the correct GTM repository for whalesync
 * This only works for prod and staging be aware of this
 */
export function startGTM(): boolean {
  // Getting the right flavor
  const flavor = getBuildFlavor();
  if (flavor !== 'staging' && flavor !== 'production') {
    // Don't run for other envs
    // We will only keep it available for Staging and Prod
    return false;
  }
  // GTM needs 3 params to work ID, AUTH and Preview(It's like the env key)
  const gtmId = process.env.NEXT_PUBLIC_GTM_ID;
  const gtmAuth = process.env.NEXT_PUBLIC_GTM_AUTH;
  const gtmPreview = process.env.NEXT_PUBLIC_GTM_PREVIEW;
  if (!isNotEmpty(gtmId) || !isNotEmpty(gtmAuth) || !isNotEmpty(gtmPreview)) {
    console.error('failed to start GTM because params were not set...');
    Bugsnag.notify('Params for GTM are empty please check your env file');
    return false;
  }

  // Based on examples from https://www.npmjs.com/package/react-gtm-module
  const tagManagerArgs = {
    gtmId: gtmId,
    auth: gtmAuth,
    preview: gtmPreview,
  };
  TagManager.initialize(tagManagerArgs);
  return true;
}
