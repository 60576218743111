import { SVGWrapper, SVGWrapperProps } from '@/components/v2/Icons/SvgWrapper';
import { getIconSize } from '@/components/v2/Icons/utils';

export const Edit05 = (props: SVGWrapperProps): JSX.Element => {
  const iconSize = getIconSize(props.size || 'sm');
  return (
    <SVGWrapper {...props}>
      <g clipPath="url(#clip0_39_26605)">
        <path d="M6.4165 2.33332H3.9665C2.98641 2.33332 2.49637 2.33332 2.12202 2.52406C1.79274 2.69184 1.52502 2.95956 1.35724 3.28884C1.1665 3.66319 1.1665 4.15323 1.1665 5.13332V10.0333C1.1665 11.0134 1.1665 11.5035 1.35724 11.8778C1.52502 12.2071 1.79274 12.4748 2.12202 12.6426C2.49637 12.8333 2.98641 12.8333 3.9665 12.8333H8.8665C9.8466 12.8333 10.3366 12.8333 10.711 12.6426C11.0403 12.4748 11.308 12.2071 11.4758 11.8778C11.6665 11.5035 11.6665 11.0134 11.6665 10.0333V7.58332M4.66649 9.33332H5.64331C5.92866 9.33332 6.07134 9.33332 6.20561 9.30109C6.32465 9.27251 6.43845 9.22537 6.54284 9.1614C6.66057 9.08926 6.76146 8.98837 6.96324 8.78659L12.5415 3.20832C13.0248 2.72507 13.0248 1.94157 12.5415 1.45832C12.0583 0.975074 11.2748 0.975074 10.7915 1.45832L5.21322 7.03659C5.01145 7.23837 4.91056 7.33926 4.83841 7.45699C4.77444 7.56138 4.7273 7.67518 4.69872 7.79422C4.66649 7.92849 4.66649 8.07117 4.66649 8.35652V9.33332Z" />
      </g>
      <defs>
        <clipPath id="clip0_39_26605">
          <rect width={iconSize} height={iconSize} fill="white" />
        </clipPath>
      </defs>
    </SVGWrapper>
  );
};
