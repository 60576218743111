import { SVGWrapper, SVGWrapperProps } from './SvgWrapper';
import { getIconSize } from './utils';

export const LinkBroken = (props: SVGWrapperProps): JSX.Element => {
  const iconSize = getIconSize(props.size || 'sm');
  return (
    <SVGWrapper {...props}>
      <g clipPath="url(#clip0_39_27241)">
        <path d="M5.24984 2.33335V1.16669M8.74984 11.6667V12.8334M2.33317 5.25002H1.1665M11.6665 8.75002H12.8332M2.86646 2.86664L2.0415 2.04169M11.1332 11.1334L11.9582 11.9584M6.99984 10.2999L5.7624 11.5373C4.85118 12.4485 3.37379 12.4485 2.46257 11.5373C1.55135 10.6261 1.55135 9.14868 2.46257 8.23746L3.70001 7.00002M10.2997 7.00002L11.5371 5.76258C12.4483 4.85136 12.4483 3.37398 11.5371 2.46275C10.6259 1.55153 9.1485 1.55153 8.23727 2.46275L6.99984 3.70019" />
      </g>
      <defs>
        <clipPath id="clip0_39_27241">
          <rect width={iconSize} height={iconSize} fill="white" />
        </clipPath>
      </defs>
    </SVGWrapper>
  );
};
