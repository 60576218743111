import { Tabs as MantineTabs } from '@mantine/core';

import styles from './Tabs.module.css';

export const Tabs = MantineTabs.extend({
  classNames() {
    return {
      list: styles.tabList,
      tab: styles.tabButton,
      root: styles.tabRoot,
    };
  },
  defaultProps: {
    color: 'blue',
  },
});
