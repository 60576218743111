import { SVGWrapper, SVGWrapperProps } from '@/components/v2/Icons/SvgWrapper';
import { getIconSize } from '@/components/v2/Icons/utils';

export const ArrowCircleUp = (props: SVGWrapperProps): JSX.Element => {
  const iconSize = getIconSize(props.size || 'sm');
  return (
    <SVGWrapper {...props}>
      <g clipPath="url(#clip0_39_26861)">
        <path d="M4.08317 12.0529C2.33958 11.0443 1.1665 9.15912 1.1665 6.99996C1.1665 3.7783 3.77818 1.16663 6.99984 1.16663C10.2215 1.16663 12.8332 3.7783 12.8332 6.99996C12.8332 9.15912 11.6601 11.0443 9.9165 12.0529M9.33317 7L6.99984 4.66667M6.99984 4.66667L4.6665 7M6.99984 4.66667V12.8333" />
      </g>
      <defs>
        <clipPath id="clip0_39_26861">
          <rect width={iconSize} height={iconSize} fill="white" />
        </clipPath>
      </defs>
    </SVGWrapper>
  );
};
