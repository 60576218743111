import { Stepper as MantineStepper } from '@mantine/core';
import styles from './Stepper.module.css';

export const Stepper = MantineStepper.extend({
  vars: () => {
    return {
      root: {
        '--stepper-icon-size': '22px',
      },
    };
  },
  classNames: () => {
    return {
      step: styles.step,
      stepIcon: styles.stepIcon,
      stepLabel: styles.stepLabel,
      steps: styles.steps,
      content: styles.content,
    };
  },
});
