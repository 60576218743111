import { SVGWrapper, SVGWrapperProps } from '@/components/v2/Icons/SvgWrapper';
import { getIconSize } from './utils';

export const PlayCircle = (props: SVGWrapperProps): JSX.Element => {
  const iconSize = getIconSize(props.size || 'sm');
  return (
    <SVGWrapper {...props}>
      <g clipPath="url(#clip0_39_26585)">
        <path d="M6.99984 12.8333C10.2215 12.8333 12.8332 10.2216 12.8332 6.99996C12.8332 3.7783 10.2215 1.16663 6.99984 1.16663C3.77818 1.16663 1.1665 3.7783 1.1665 6.99996C1.1665 10.2216 3.77818 12.8333 6.99984 12.8333Z" />
        <path d="M5.5415 5.22974C5.5415 4.95132 5.5415 4.81211 5.59969 4.7344C5.65039 4.66667 5.728 4.6243 5.81239 4.61827C5.90923 4.61135 6.02633 4.68663 6.26052 4.83719L9.0142 6.60741C9.21743 6.73805 9.31904 6.80338 9.35414 6.88644C9.3848 6.95901 9.3848 7.04091 9.35414 7.11348C9.31904 7.19654 9.21743 7.26186 9.0142 7.39251L6.26052 9.16273C6.02633 9.31329 5.90923 9.38857 5.81239 9.38165C5.728 9.37562 5.65039 9.33325 5.59969 9.26552C5.5415 9.18781 5.5415 9.0486 5.5415 8.77018V5.22974Z" />
      </g>
      <defs>
        <clipPath id="clip0_39_26585">
          <rect width={iconSize} height={iconSize} fill="white" />
        </clipPath>
      </defs>
    </SVGWrapper>
  );
};
