import { SVGWrapper, SVGWrapperProps } from '@/components/v2/Icons/SvgWrapper';
import { getIconSize } from './utils';

export const PauseCircle = (props: SVGWrapperProps): JSX.Element => {
  const iconSize = getIconSize(props.size || 'sm');
  return (
    <SVGWrapper {...props}>
      <g clipPath="url(#clip0_39_26559)">
        <path d="M5.5415 8.74996V5.24996M8.45817 8.74996V5.24996M12.8332 6.99996C12.8332 10.2216 10.2215 12.8333 6.99984 12.8333C3.77818 12.8333 1.1665 10.2216 1.1665 6.99996C1.1665 3.7783 3.77818 1.16663 6.99984 1.16663C10.2215 1.16663 12.8332 3.7783 12.8332 6.99996Z" />
      </g>
      <defs>
        <clipPath id="clip0_39_26559">
          <rect width={iconSize} height={iconSize} fill="white" />
        </clipPath>
      </defs>
    </SVGWrapper>
  );
};
