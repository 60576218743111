import * as DialogPrimitive from '@radix-ui/react-dialog';
import { Cross2Icon } from '@radix-ui/react-icons';
import React, { FC, ForwardedRef, ReactNode, RefAttributes, useEffect, useState } from 'react';
import { CSS, keyframes, styled } from 'stitches.config';
import WSButton from './ws-button';
import { WSFlex } from './ws-flex';

const overlayShow = keyframes({
  '0%': { opacity: 0 },
  '100%': { opacity: 1 },
});

const StyledOverlay = styled(DialogPrimitive.Overlay, {
  backgroundColor: 'rgba(0, 0, 0, 0.8)',
  position: 'fixed',
  inset: 0,
  zIndex: '$dialogSkrim',
  '@media (prefers-reduced-motion: no-preference)': {
    animation: `${overlayShow} 150ms cubic-bezier(0.16, 1, 0.3, 1) forwards`,
  },
});

const StyledContent = styled(DialogPrimitive.Content, {
  backgroundColor: 'white',
  borderRadius: 12,
  boxShadow:
    '0px 4px 8px rgba(13, 16, 18, 0.08), 0px 2px 6px rgba(13, 16, 18, 0.08), 0px 12px 20px rgba(13, 16, 18, 0.08)',
  position: 'fixed',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: '490px',
  maxWidth: '80vw',
  maxHeight: '90vh',
  overflowY: 'auto',
  padding: 25,
  zIndex: '$dialogContent',
  '&:focus': { outline: 'none' },
});
interface ContentProps {
  children: ReactNode;
  props?: DialogPrimitive.DialogContentProps & RefAttributes<HTMLDivElement>;
  css?: CSS;
}

const Content: FC<ContentProps> = ({ css, children, ...props }) => {
  return (
    <DialogPrimitive.Portal>
      <StyledOverlay />
      <StyledContent css={css} {...props}>
        {children}
      </StyledContent>
    </DialogPrimitive.Portal>
  );
};

const StyledTitle = styled(DialogPrimitive.Title, {
  margin: '0 0 18px 0',
  fontWeight: '$semiBold',
  color: '$gray8',
  lineHeight: '40px',
  fontSize: '$h2',
});

const StyledSubtitle = styled(DialogPrimitive.Title, {
  margin: 0,
  fontWeight: '$semiBold',
  color: '$gray8',
  lineHeight: '40px',
  fontSize: '$h3',
});

const StyledDescription = styled(DialogPrimitive.Description, {
  margin: '8px 0 16px',
  fontSize: '$bodyMedium',
  fontWeight: '$light',
  lineHeight: '150%',
  color: '$gray6',
});

// Exports
export const WSDialog = DialogPrimitive.Root;
export const WSDialogTrigger = DialogPrimitive.Trigger;
export function WSDialogContent(props: ContentProps): JSX.Element {
  return (
    // Wrap dialog content to capture all clicks
    <div onClick={(e) => e.stopPropagation()}>
      <Content {...props} />
    </div>
  );
}
export const WSDialogTitle = StyledTitle;
export const WSDialogSubtitle = StyledSubtitle;
export const WSDialogDescription = StyledDescription;

const StyledCloseUpperRight = styled('button', {
  all: 'unset',
  fontFamily: 'inherit',
  borderRadius: '100%',
  height: 25,
  width: 25,
  display: 'inline-flex',
  alignItems: 'center',
  justifyContent: 'center',
  color: '$primary',
  position: 'absolute',
  top: 10,
  right: 10,
  transition: '$wsHoverTransition',
  cursor: 'pointer',
  '&:hover': { backgroundColor: '$primaryWithAlpha' },
  '&:focus': { boxShadow: `0 0 0 2px $primaryWithAlpha` },
});

interface DialogCloseProps {
  onClick?: () => void;
}

export const WSDialogCloseUpperRight: FC<DialogCloseProps> = (props: { onClick?: () => void }) => {
  return (
    <WSDialogCustomButton>
      <WSCloseUpperRightXButton onClick={props.onClick} />
    </WSDialogCustomButton>
  );
};

/**
 * The 'X' close button we use in a dialog, but without dialog-specific wrappers.
 * This is to use when you want the same widget OUTSIDE of a dialog.
 * If you're building a dialog, use `WSContentCloseUpperRight` instead.
 */
// Forward ref gets rid of this warning:
// https://stackoverflow.com/questions/62645556/react-warning-function-components-cannot-be-given-refs
export const WSCloseUpperRightXButton = React.forwardRef(
  (props: { onClick?: () => void }, ref: ForwardedRef<HTMLButtonElement>) => {
    return (
      <StyledCloseUpperRight onClick={props.onClick} ref={ref}>
        <Cross2Icon />
      </StyledCloseUpperRight>
    );
  },
);

export function WSDialogButtons(props: {
  children: ReactNode | ReactNode[];
  position?: 'right' | 'center' | 'spread'; // default right.
  center?: boolean;
}): JSX.Element {
  const children = Array.isArray(props.children) ? props.children : [props.children];
  return (
    <WSFlex
      wrap={props.position === 'spread' ? 'noWrap' : 'wrap'}
      justify={props.position === 'spread' ? 'between' : props.position === 'center' ? 'center' : 'end'}
      css={{ gap: '12px', paddingTop: '18px' }}
    >
      {children}
    </WSFlex>
  );
}

export function WSDialogCustomButton(props: { children: ReactNode; onClick?: () => void }): JSX.Element {
  return (
    <DialogPrimitive.Close asChild onClick={props.onClick}>
      {props.children}
    </DialogPrimitive.Close>
  );
}

export function WSDialogCancelButton(props: { onClick?: () => void }): JSX.Element {
  return (
    <WSDialogCustomButton>
      <WSButton variant="ghost" onClick={props.onClick}>
        Cancel
      </WSButton>
    </WSDialogCustomButton>
  );
}

export const WSAlertDialog = ({
  trigger,
  title,
  description,
  confirm,
  cancel,
  open: defaultOpen,
  onChange,
}: {
  trigger?: ReactNode;
  title: string;
  description: string;
  confirm: ReactNode;
  cancel: ReactNode;
  open?: boolean;
  onChange?: (open: boolean) => void;
}): JSX.Element => {
  const [, setOpen] = useState(!!defaultOpen);

  useEffect(() => {
    setOpen(!!defaultOpen);
  }, [defaultOpen]);
  return (
    <div onClick={(e) => e.stopPropagation()}>
      <WSDialog
        {...(defaultOpen && { open: defaultOpen })}
        onOpenChange={(open): void => {
          onChange && onChange(open);
        }}
      >
        <WSDialogTrigger
          asChild
          onClick={(e) => {
            setOpen((open) => !open);
            e.stopPropagation();
          }}
        >
          {trigger}
        </WSDialogTrigger>
        <WSDialogContent>
          <WSFlex align="center" justify="between">
            <WSDialogTitle>{title}</WSDialogTitle>
          </WSFlex>
          <WSDialogDescription>{description}</WSDialogDescription>
          <WSDialogButtons>
            <WSDialogCustomButton>{cancel}</WSDialogCustomButton>
            <WSDialogCustomButton>{confirm}</WSDialogCustomButton>
          </WSDialogButtons>
        </WSDialogContent>
      </WSDialog>
    </div>
  );
};
