import { SVGWrapper, SVGWrapperProps } from '@/components/v2/Icons/SvgWrapper';
import { getIconSize } from '@/components/v2/Icons/utils';

export const Copy = (props: SVGWrapperProps): JSX.Element => {
  const iconSize = getIconSize(props.size || 'sm');
  return (
    <SVGWrapper {...props}>
      <g clipPath="url(#clip0_39_27052)">
        <path d="M2.9165 8.75002C2.37291 8.75002 2.10111 8.75002 1.88671 8.66121C1.60084 8.5428 1.37372 8.31568 1.25531 8.02982C1.1665 7.81542 1.1665 7.54362 1.1665 7.00002V3.03335C1.1665 2.37996 1.1665 2.05326 1.29366 1.8037C1.40552 1.58418 1.58399 1.4057 1.80351 1.29385C2.05308 1.16669 2.37978 1.16669 3.03317 1.16669H6.99984C7.54344 1.16669 7.81523 1.16669 8.02963 1.25549C8.3155 1.3739 8.54262 1.60102 8.66103 1.88689C8.74984 2.10129 8.74984 2.37309 8.74984 2.91669M7.1165 12.8334H10.9665C11.6199 12.8334 11.9466 12.8334 12.1962 12.7062C12.4157 12.5943 12.5942 12.4159 12.706 12.1963C12.8332 11.9468 12.8332 11.6201 12.8332 10.9667V7.11669C12.8332 6.46329 12.8332 6.1366 12.706 5.88703C12.5942 5.66751 12.4157 5.48903 12.1962 5.37718C11.9466 5.25002 11.6199 5.25002 10.9665 5.25002H7.1165C6.46311 5.25002 6.13641 5.25002 5.88685 5.37718C5.66733 5.48903 5.48885 5.66751 5.377 5.88703C5.24984 6.1366 5.24984 6.46329 5.24984 7.11669V10.9667C5.24984 11.6201 5.24984 11.9468 5.377 12.1963C5.48885 12.4159 5.66733 12.5943 5.88685 12.7062C6.13641 12.8334 6.46311 12.8334 7.1165 12.8334Z" />
      </g>
      <defs>
        <clipPath id="clip0_39_27052">
          <rect width={iconSize} height={iconSize} fill="white" />
        </clipPath>
      </defs>
    </SVGWrapper>
  );
};
