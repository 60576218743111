import { SVGWrapper, SVGWrapperProps } from '@/components/v2/Icons/SvgWrapper';

export const Gear = (props: SVGWrapperProps): JSX.Element => {
  return (
    <SVGWrapper {...props}>
      <path d="M5.4806 11.2998L5.82153 12.0666C5.92288 12.2948 6.08828 12.4888 6.29766 12.6249C6.50705 12.761 6.75143 12.8334 7.00116 12.8334C7.25089 12.8334 7.49527 12.761 7.70466 12.6249C7.91404 12.4888 8.07944 12.2948 8.18079 12.0666L8.52171 11.2998C8.64308 11.0278 8.84722 10.8009 9.10505 10.6517C9.36451 10.502 9.66466 10.4383 9.96255 10.4696L10.7967 10.5584C11.045 10.5846 11.2956 10.5383 11.5181 10.425C11.7406 10.3116 11.9255 10.1362 12.0502 9.91993C12.1752 9.70378 12.2347 9.45602 12.2216 9.2067C12.2085 8.95739 12.1233 8.71724 11.9763 8.51539L11.4825 7.83678C11.3066 7.59335 11.2126 7.30033 11.2141 7.00002C11.2141 6.70053 11.3089 6.40872 11.485 6.1665L11.9789 5.48789C12.1259 5.28604 12.2111 5.04589 12.2242 4.79658C12.2373 4.54727 12.1778 4.2995 12.0528 4.08335C11.928 3.86707 11.7432 3.69164 11.5207 3.57832C11.2982 3.465 11.0476 3.41866 10.7993 3.44493L9.96514 3.53372C9.66725 3.56501 9.3671 3.50126 9.10764 3.35159C8.8493 3.2015 8.6451 2.97348 8.52431 2.70021L8.18079 1.93345C8.07944 1.7052 7.91404 1.51127 7.70466 1.37517C7.49527 1.23907 7.25089 1.16665 7.00116 1.16669C6.75143 1.16665 6.50705 1.23907 6.29766 1.37517C6.08828 1.51127 5.92288 1.7052 5.82153 1.93345L5.4806 2.70021C5.35981 2.97348 5.15561 3.2015 4.89727 3.35159C4.63781 3.50126 4.33766 3.56501 4.03977 3.53372L3.20301 3.44493C2.9547 3.41866 2.70411 3.465 2.48161 3.57832C2.25911 3.69164 2.07427 3.86707 1.94949 4.08335C1.82455 4.2995 1.76503 4.54727 1.77815 4.79658C1.79126 5.04589 1.87645 5.28604 2.02338 5.48789L2.51727 6.1665C2.6934 6.40872 2.78825 6.70053 2.7882 7.00002C2.78825 7.29951 2.6934 7.59132 2.51727 7.83354L2.02338 8.51215C1.87645 8.714 1.79126 8.95415 1.77815 9.20346C1.76503 9.45278 1.82455 9.70054 1.94949 9.91669C2.07439 10.1329 2.25926 10.3082 2.48172 10.4215C2.70419 10.5348 2.95472 10.5812 3.20301 10.5551L4.03718 10.4663C4.33507 10.435 4.63522 10.4988 4.89468 10.6484C5.15399 10.7981 5.35913 11.0262 5.4806 11.2998Z" />
      <path d="M7.00012 8.75002C7.96662 8.75002 8.75012 7.96652 8.75012 7.00002C8.75012 6.03352 7.96662 5.25002 7.00012 5.25002C6.03362 5.25002 5.25012 6.03352 5.25012 7.00002C5.25012 7.96652 6.03362 8.75002 7.00012 8.75002Z" />
    </SVGWrapper>
  );
};
