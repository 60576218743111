const iconSizeMap = {
  xs: '12px',
  sm: '14px',
};

export const getIconSize = (size: 'sm' | 'xs' | number): string => {
  const isCustomSize = typeof size === 'number';
  const iconSize = isCustomSize ? `${size}px` : iconSizeMap[size];

  return iconSize;
};
