import { SVGWrapper, SVGWrapperProps } from '@/components/v2/Icons/SvgWrapper';
import { getIconSize } from '@/components/v2/Icons/utils';

export const Copy07 = (props: SVGWrapperProps): JSX.Element => {
  const iconSize = getIconSize(props.size || 'sm');
  return (
    <SVGWrapper {...props}>
      <g clipPath="url(#clip0_39_26921)">
        <path d="M6.12484 1.16832C5.73106 1.17365 5.49466 1.19645 5.30351 1.29385C5.08399 1.4057 4.90552 1.58418 4.79366 1.8037C4.69627 1.99484 4.67347 2.23124 4.66813 2.62502M11.3748 1.16832C11.7686 1.17365 12.005 1.19645 12.1962 1.29385C12.4157 1.4057 12.5942 1.58418 12.706 1.8037C12.8034 1.99484 12.8262 2.23124 12.8315 2.62501M12.8315 7.87501C12.8262 8.2688 12.8034 8.50519 12.706 8.69634C12.5942 8.91586 12.4157 9.09434 12.1962 9.20619C12.005 9.30359 11.7686 9.32639 11.3748 9.33172M12.8332 4.66668V5.83335M8.16653 1.16669H9.33315M3.03317 12.8334H7.4665C8.1199 12.8334 8.4466 12.8334 8.69616 12.7062C8.91568 12.5943 9.09416 12.4159 9.20601 12.1963C9.33317 11.9468 9.33317 11.6201 9.33317 10.9667V6.53335C9.33317 5.87996 9.33317 5.55326 9.20601 5.3037C9.09416 5.08418 8.91568 4.9057 8.69616 4.79385C8.4466 4.66669 8.1199 4.66669 7.4665 4.66669H3.03317C2.37978 4.66669 2.05308 4.66669 1.80351 4.79385C1.58399 4.9057 1.40552 5.08418 1.29366 5.3037C1.1665 5.55326 1.1665 5.87996 1.1665 6.53335V10.9667C1.1665 11.6201 1.1665 11.9468 1.29366 12.1963C1.40552 12.4159 1.58399 12.5943 1.80351 12.7062C2.05308 12.8334 2.37978 12.8334 3.03317 12.8334Z" />
      </g>
      <defs>
        <clipPath id="clip0_39_26921">
          <rect width={iconSize} height={iconSize} fill="white" />
        </clipPath>
      </defs>
    </SVGWrapper>
  );
};
