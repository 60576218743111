import { FLAGS } from '@/utils/flags-dev';
import Image from 'next/image';
import Link from 'next/link';
import { ProductType } from '../../lib/client/v1/entities';
import { styled } from '../../stitches.config';
import { Flavor, getBuildFlavor } from '../../utils/flags';
import { RouteUrls } from '../../utils/route-urls';
import { UserInfo } from '../../utils/types';
import WSBadge from '../base-widgets/ws-badge';
import WSButton from '../base-widgets/ws-button';
import { WSDialog, WSDialogContent, WSDialogTitle } from '../base-widgets/ws-dialog';
import { WSFlex } from '../base-widgets/ws-flex';
import WSText from '../base-widgets/ws-text';

// On prod we direct users to the pricing page on the website.
// On staging, test, and localhost, skip past the product page, and go straight to signing up for a basic plan.
const PROD_PICK_PLAN_URL = 'https://www.whalesync.com/pricing';
const OTHER_ENV_PICK_PLAN_URL = RouteUrls.v2AccountSignupWithProductType(`${ProductType.SOLO_1_V90}`);

export type SubscriptionRequiredModalProps = {
  userInfo: UserInfo;
  onSwitchAccounts: () => void;
};

const StyledDialogContentContainer = styled(WSFlex, { gap: '16px', marginTop: '30px' });

const StyledImage = styled(Image, { maxWidth: '100%' });

const SubscriptionRequiredModal = ({ userInfo, onSwitchAccounts }: SubscriptionRequiredModalProps): JSX.Element => {
  const pickPlanUrl = getBuildFlavor() === Flavor.Production ? PROD_PICK_PLAN_URL : OTHER_ENV_PICK_PLAN_URL;

  let title = null;
  let content = null;
  if (!userInfo.whalesyncUser.subscription) {
    title = 'Choose a plan to get started';
    content = (
      <StyledDialogContentContainer direction="column" align="center">
        <WSText variant="body" css={{ textAlign: 'center' }}>
          {buildWelcomeString(userInfo)}
        </WSText>
        <WSText variant="body" css={{ textAlign: 'center' }}>
          Whatever your needs may be, we have a plan to help you get your data synced quickly and effectively.
        </WSText>

        <StyledImage
          src="/assets/all-the-various-services.svg"
          alt="Sync all your services with Whalesync"
          height={253}
          width={484}
        />
        <Link href={pickPlanUrl} passHref legacyBehavior>
          <WSButton css={{ '-webkit-transition': `background-color 200ms ease-in-out` }}>Choose a plan</WSButton>
        </Link>
        <SwitchAccountsButton onSwitchAccounts={onSwitchAccounts} />
        {userInfo.email && <SignedInAs email={userInfo.email} />}
      </StyledDialogContentContainer>
    );
  } else if (userInfo.whalesyncUser.subscription.status === 'expired') {
    title = 'Your subscription has expired';
    content = (
      <StyledDialogContentContainer direction="column" align="center">
        <WSText variant="body" css={{ textAlign: 'center' }}>
          Please update your subscription to continue using Whalesync. You can reach us at{' '}
          <a href="mailto:hello@whalesync.com">hello@whalesync.com</a> if you have any questions.
        </WSText>
        <WSBadge size="large" variant="accent">
          Don&apos;t lose out on what you&apos;ve started!
        </WSBadge>
        <Link href={pickPlanUrl} legacyBehavior>
          <WSButton css={{ marginTop: '40px' }}>Pick a new plan</WSButton>
        </Link>
        <SwitchAccountsButton onSwitchAccounts={onSwitchAccounts} />
        {userInfo.email && <SignedInAs email={userInfo.email} />}
      </StyledDialogContentContainer>
    );
  }
  if (content === null || (FLAGS.SKIP_PAYWALL_FOR_LOCALHOST.get() && getBuildFlavor() === Flavor.Local)) {
    return <></>;
  }
  return (
    <WSDialog open={true}>
      <WSDialogContent css={{ maxWidth: '800px' }}>
        <WSDialogTitle css={{ textAlign: 'center' }}>
          <WSText variant="h2" css={{ color: '$gray8', lineHeight: '40px' }}>
            {title}
          </WSText>
        </WSDialogTitle>
        {content}
      </WSDialogContent>
    </WSDialog>
  );
};

function buildWelcomeString(userInfo: UserInfo): string {
  let welcomeString = 'Welcome';
  if (userInfo.whalesyncUser.firstName) {
    welcomeString += ` ${userInfo.whalesyncUser.firstName}`;
  } else if (userInfo.displayName) {
    welcomeString += ` ${userInfo.displayName}`;
  }
  if (userInfo.email) {
    welcomeString += ` (${userInfo.email})`;
  }
  return welcomeString + '!';
}

function SignedInAs(props: { email: string }): JSX.Element {
  return <WSText variant="bodySmall">Signed In as {props.email}</WSText>;
}

function SwitchAccountsButton(props: { onSwitchAccounts: () => void }): JSX.Element {
  // TODO use auth state signout
  return (
    <WSButton size="small" variant="ghost" onClick={props.onSwitchAccounts}>
      Switch accounts
    </WSButton>
  );
}

export default SubscriptionRequiredModal;
