import { CSSVariablesResolver, DefaultMantineColor, createTheme } from '@mantine/core';
import { Archivo } from 'next/font/google';

const archivo = Archivo({ subsets: ['latin'] });

// NOTE! If you are changing these, also adjust _document.tsx, which loads the sizes from google fonts.
const FONT_WEIGHTS = {
  light: '300',
  book: '400',
  regular: '450',
  medium: '450',
  semiBold: '550',
  bold: '650',
};

/**
 * Jonas' new font settings -- to replace archivo in the redesign
 const inter = Inter({ subsets: ['latin'] }); 

 const FONT_WEIGHTS = {
  light: '300',
  book: '400',
  regular: '450',
  medium: '500',
  semiBold: '600',
  bold: '700',
};
 */

/**
 * Defines the basic theme, colors, fonts, etc for Mantine widgets.
 * See https://mantine.dev/theming/theme-object/
 */
export const MANTINE_THEME = createTheme({
  cursorType: 'pointer',
  colors: {
    // Our fancy blue. See 'primaryShade' for the default variant of it.
    primary: [
      '#EBF9FF',
      '#B8E9FF',
      '#85DAFF',
      '#52CAFF',
      '#1FBBFF',
      '#00A2E9',
      '#0080B8',
      '#005C85',
      '#003952',
      '#00151f',
    ],
    // Our secondary purple.
    secondary: [
      '#F6EBFF',
      '#DEB8FF',
      '#C685FF',
      '#AF52FF',
      '#971FFF',
      '#7D00E9',
      '#6300B8',
      '#470085',
      '#2C0052',
      '#10001F',
    ],
    // Whalesync gray™
    wsGray: [
      '#F3F5F6',
      '#D6DDE1',
      '#B9C5CB',
      '#9CADB5',
      '#7E959F',
      '#647C86',
      '#4E6169',
      '#39464C',
      '#232B2F',
      '#0d1012',
    ],
    // Whalesync green™
    // TODO(ryder): These aren't gradienting properly, just have 0 (very light), 2 (light), 5 (normal), 8 (dark)
    wsGreen: [
      '#F2FDF8',
      '#F2FDF8',
      '#85FFCC',
      '#85FFCC',
      '#85FFCC',
      '#00D67C',
      '#00D67C',
      '#00D67C',
      '#00522F',
      '#00522F',
    ], // Whalesync red
    // TODO(ryder): These aren't gradienting properly, just have 0 (very light), 2 (light), 5 (normal), 8 (dark)
    wsRed: [
      '#FFE6EF',
      '#FFE6EF',
      '#FF85B0',
      '#FF85B0',
      '#FF85B0',
      '#EB0054',
      '#EB0054',
      '#EB0054',
      '#52001D',
      '#52001D',
    ],
    gray: [
      'transparent',
      '#FCFCFC',
      '#F9F9F9',
      '#F0F0F0',
      '#EBEBEB',
      '#E6E6E6',
      '#E8E8E8',
      '#D6D6D6',
      '#C7C7C7',
      '#8D8D8D',
      '#838383',
      '#646464',
      '#202020',
    ],
    'gray-a': [
      'transparent',
      'rgba(0, 0, 0, 0.0118)',
      'rgba(0, 0, 0, 0.0235)',
      'rgba(0, 0, 0, 0.0588)',
      'rgba(0, 0, 0, 0.08)',
      'rgba(0, 0, 0, 0.098)',
      'rgba(0, 0, 0, 0.11)',
      'rgba(0, 0, 0, 0.16)',
      'rgba(0, 0, 0, 0.22)',
      'rgba(0, 0, 0, 0.4471)',
      'rgba(0, 0, 0, 0.4863)',
      'rgba(0, 0, 0, 0.6078)',
      'rgba(0, 0, 0, 0.8745)',
    ],
    blue: [
      'transparent',
      '#FAFDFF',
      '#F3FAFE',
      '#E4F4FF',
      '#D2EEFF',
      '#BFE5FF',
      '#A9D8F9',
      '#8CC8F1',
      '#5BB1E8',
      '#05A1E9',
      '#0295D8',
      '#0079BB',
      '#073954',
    ],
    'blue-a': [
      'transparent',
      'rgba(0, 153, 255, 0.0196)',
      'rgba(0, 149, 234, 0.0471)',
      'rgba(0, 152, 255, 0.1059)',
      'rgba(0, 159, 255, 0.1765)',
      'rgba(0, 152, 255, 0.251)',
      'rgba(0, 140, 238, 0.3373)',
      'rgba(0, 134, 224, 0.451)',
      'rgba(0, 134, 220, 0.6431)',
      'rgba(0, 159, 233, 0.9804)',
      'rgba(0, 148, 216, 0.9922)',
      'rgba(0, 121, 187, 1)',
      'rgba(0, 51, 79, 0.9725)',
    ],
  },
  primaryColor: 'primary',
  primaryShade: 5,

  // Default text styles used everywhere.
  fontFamily: archivo.style.fontFamily,
  fontSizes: {
    '2xs': '12px',
    xs: '13px',
    sm: '14px',
    md: '16px',
    lg: '18px',
  },
  lineHeights: {
    xs: '125%',
    sm: '125%',
    md: '150%',
    lg: '160%',
    xl: '170%',
  },

  radius: {
    xs: '0.25rem',
    sm: '0.375rem',
    md: '0.5rem',
    lg: '0.625rem',
    full: '100%',
  },

  // Header styles, used in Title.
  headings: {
    fontWeight: FONT_WEIGHTS.semiBold,
    sizes: {
      h1: { fontSize: '32px', lineHeight: '125%' },
      h2: { fontSize: '24px', lineHeight: '125%' },
      h3: { fontSize: '18px', lineHeight: '150%' },
      h4: { fontSize: '16px', lineHeight: '125%' },
      h5: { fontSize: '14px', lineHeight: '125%' },
    },
  },

  other: {
    // Anything added here also needs to go into the mantineThemeCssVariableResolver.
    fontWeightLight: FONT_WEIGHTS.light,
    fontWeightMedium: FONT_WEIGHTS.medium,
    fontWeightSemiBold: FONT_WEIGHTS.semiBold,
    fontWeightBold: FONT_WEIGHTS.bold,
  },

  shadows: {
    focus: '0px 0px 0px 2px var(--mantine-color-blue-a-6)',
    md: '0px 1px 2px -0.5px var(--mantine-color-gray-a-3), 0px 1px 3px -1px var(--mantine-color-gray-a-3)',
    'md-inner':
      '0px 1px 1px 1px rgba(255, 255, 255, 0.20) inset, 0px 1px 2px -0.5px var(--mantine-color-gray-a-3), 0px 1px 3px -1px var(--mantine-color-gray-a-3)',
    'md-focus':
      '0px 0px 0px 2px var(--mantine-color-blue-a-6), 0px 1px 2px -1px var(--mantine-color-gray-a-3), 0px 1px 3px -1px var(--mantine-color-gray-a-3)',
  },
});

// Extra variables we want exposed in CSS.
export const MANTINE_THEME_CSS_VARIABLE_RESOLVER: CSSVariablesResolver = (theme) => ({
  variables: {
    '--mantine-font-weight-light': theme.other.fontWeightLight,
    '--mantine-font-weight-medium': theme.other.fontWeightMedium,
    '--mantine-font-weight-semiBold': theme.other.fontWeightSemiBold,
    '--mantine-font-weight-bold': theme.other.fontWeightBold,
  },
  light: {},
  dark: {},
});

type ExtendedCustomColors = 'primary' | 'secondary' | 'wsGray' | 'wsGreen' | 'wsRed' | DefaultMantineColor;

declare module '@mantine/core' {
  export interface MantineThemeColorsOverride {
    colors: Record<
      ExtendedCustomColors,
      [string, string, string, string, string, string, string, string, string, string]
    >;
  }
}
