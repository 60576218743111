import { ThemeIcon as MantineThemeIcon } from '@mantine/core';
import styles from './ThemeIcon.module.css';

export const ThemeIcon = MantineThemeIcon.extend({
  vars: (theme, props) => {
    if (props.variant === 'white') {
      return {
        root: {
          '--ti-color': props.color || theme.colors.gray[9],
          '--ti-bg': 'transparent',
          '--ti-bd': 'none',
        },
      };
    }

    return {
      root: {
        '--ti-color': props.color || theme.colors.gray[9],
      },
    };
  },
  classNames: () => {
    return {
      root: styles.icon,
    };
  },
  defaultProps: {
    size: 'sm',
    variant: 'white',
  },
});
