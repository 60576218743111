import { useRouter } from 'next/router';
import { ReactNode } from 'react';

import SubscriptionRequiredModal from '@/components/common/subscription-required-modal';
import { useClerkAuth } from '@/components/contexts/clerk-auth';
import { RouteUrls } from '@/utils/route-urls';
import { IS_V2_UI } from '../../utils/flags';
import { NoSubscriptionDetectedModal } from '../v2/common/NoSubscriptionDetectedModal';

export const SubscriptionContainer = ({ children }: { children: ReactNode }): JSX.Element => {
  const { authState, signOut } = useClerkAuth();
  const { pathname } = useRouter();

  // render subscription modal for pages which requires subscription
  if (RouteUrls.isSubscribedOnlyRoute(pathname)) {
    let subscriptionRequiredModal: JSX.Element = <></>;

    if (IS_V2_UI) {
      subscriptionRequiredModal = <NoSubscriptionDetectedModal />;
    } else if (authState.user) {
      subscriptionRequiredModal = <SubscriptionRequiredModal userInfo={authState.user} onSwitchAccounts={signOut} />;
    }

    return (
      <>
        {subscriptionRequiredModal}
        {children}
      </>
    );
  }

  return <>{children}</>;
};
