import Bugsnag from '@bugsnag/js';
import BugsnagPluginReact, { BugsnagErrorBoundary } from '@bugsnag/plugin-react';
import React from 'react';
import { Flavor, getBuildFlavor, getBuildVersion } from '../../utils/flags';

const flavor = getBuildFlavor();
const version = getBuildVersion();

// Borrowed from https://github.com/bugsnag/bugsnag-js/tree/master/examples/js/nextjs.
export function startBugsnag():
  | React.ExoticComponent<{
      children?: React.ReactNode;
    }>
  | BugsnagErrorBoundary {
  // next.js executes top-level code at build time, so use NEXT_PHASE to avoid Bugsnag.start being executed during the
  // build phase.
  if (
    process.env.NEXT_PHASE !== 'phase-production-build' &&
    typeof process.env.NEXT_PUBLIC_BUGSNAG_API_KEY === 'string'
  ) {
    Bugsnag.start({
      apiKey: process.env.NEXT_PUBLIC_BUGSNAG_API_KEY,
      appVersion: version,
      releaseStage: flavor,
      enabledReleaseStages: [Flavor.Production, Flavor.Staging], // Not enabled in test
      plugins: [new BugsnagPluginReact()],
    });

    return Bugsnag.getPlugin('react')?.createErrorBoundary(React) ?? React.Fragment;
  }

  return React.Fragment;
}
