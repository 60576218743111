import { SVGWrapper, SVGWrapperProps } from '@/components/v2/Icons/SvgWrapper';

// A prohibited or do-not enter icon
export const SlashCircle = (props: SVGWrapperProps): JSX.Element => {
  return (
    <SVGWrapper {...props}>
      <path d="M2.87567 2.87585L11.124 11.1242M12.8332 7.00002C12.8332 10.2217 10.2215 12.8334 6.99984 12.8334C3.77818 12.8334 1.1665 10.2217 1.1665 7.00002C1.1665 3.77836 3.77818 1.16669 6.99984 1.16669C10.2215 1.16669 12.8332 3.77836 12.8332 7.00002Z" />
    </SVGWrapper>
  );
};
