import { getIconSize } from '@/components/v2/Icons/utils';
import { getThemeColor, parseThemeColor, useMantineTheme } from '@mantine/core';
import React from 'react';

export interface SVGWrapperProps extends React.SVGProps<SVGSVGElement> {
  size?: 'sm' | 'xs' | number;
}

export const SVGWrapper: React.FC<SVGWrapperProps> = ({ children, size = 'sm', ...props }) => {
  const theme = useMantineTheme();

  const strokeMap = {
    xs: '1.25',
    sm: '1.5',
  };

  const isCustomSize = typeof size === 'number';
  const iconSize = getIconSize(size);
  const iconStroke = isCustomSize ? '' : strokeMap[size];
  const color = getThemeColor(props.stroke, theme);

  const parsedColor = parseThemeColor({ color, theme });

  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      fill="none"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={iconStroke}
      viewBox="0 0 14 14"
      width={iconSize}
      height={iconSize}
      {...props}
      stroke={props?.stroke ? parsedColor?.color || props.stroke : 'currentColor'}
    >
      {children}
    </svg>
  );
};
