import { CheckCircle, Info, Warning, WarningOctagon, X } from '@phosphor-icons/react';
import * as ToastPrimitive from '@radix-ui/react-toast';
import { keyframes, styled } from '@stitches/react';
import * as React from 'react';
import { v4 as uuidv4 } from 'uuid';
import { config } from '../../stitches.config';
import { WSFlex } from './ws-flex';

/** Context to get a handle to the toast publisher. */
export const WSToastPublisherContext = React.createContext<React.RefObject<WSToastPublisher> | null>(null);

/** The object provided by the WSToastPublisherContext, use it imperitively to show a toast. */
export type WSToastPublisher = {
  publish: (toast: WSToastDescription) => void;
  clearChannel: (channel: string) => void;
};

/** Data to fill in to create a toast. */
export type WSToastDescription = {
  title: string;
  body: string | JSX.Element;
  severity: 'success' | 'warning' | 'failure' | 'info';

  /**
   * An optional arbitrary identifier for the source of this message.
   * If provided, only one toast from this channel will be visible at a time, and older ones will be dismissed when a
   * new one is posted.
   */
  channel?: string;
};

/** Internal representation that adds an ID to each toast for bookkeeping. */
type WSToastDescriptionWithId = WSToastDescription & { id: string };

/**
 * Object that publishes toasts.
 * This should be installed at the top-level of the app, just inside the WSToastProvider.
 *
 * To use it, get a reference from the WSToastPublisherContext and call `.publish()`.
 * See Component Playground for an example.
 */
export const WSToastPublisher = React.forwardRef<WSToastPublisher, unknown>((props, forwardedRef) => {
  const [toasts, setToasts] = React.useState<WSToastDescriptionWithId[]>([]);

  React.useImperativeHandle(forwardedRef, () => ({
    publish: (toast: WSToastDescription) => {
      // Remove any toasts we already have for this channel.
      const newToasts = toasts.filter((t) => t.channel === undefined || t.channel !== toast.channel);
      newToasts.push({ ...toast, id: uuidv4() });
      setToasts(newToasts);
    },
    clearChannel: (channel: string) => {
      const newToasts = toasts.filter((t) => t.channel !== channel);
      setToasts(newToasts);
    },
  }));

  return (
    <>
      {toasts.map((toast) => (
        <StyledToast key={toast.id} severity={toast.severity}>
          <WSFlex align="center" css={{ gap: '4px' }}>
            <SeverityIcon severity={toast.severity} />
            <StyledTitle>{toast.title}</StyledTitle>
          </WSFlex>
          <StyledDescription>{toast.body}</StyledDescription>
          <StyledToastClose className="toast-close-button">
            <X size={24} color={config.theme.colors.gray6} />
          </StyledToastClose>
        </StyledToast>
      ))}
    </>
  );
});

function SeverityIcon(props: { severity: WSToastDescription['severity'] }): JSX.Element {
  switch (props.severity) {
    case 'success':
      return <CheckCircle size={24} color={config.theme.colors.wsGreen} style={{ alignSelf: 'baseline' }} />;
    case 'warning':
      return <Warning size={24} color={config.theme.colors.wsOrange} style={{ alignSelf: 'baseline' }} />;
    case 'failure':
      return <WarningOctagon size={24} color={config.theme.colors.wsRed} style={{ alignSelf: 'baseline' }} />;
    case 'info':
      return <Info size={24} color={config.theme.colors.gray6} style={{ alignSelf: 'baseline' }} />;
  }
}

const VIEWPORT_PADDING = 24;

const hide = keyframes({
  '0%': { opacity: 1 },
  '100%': { opacity: 0 },
});

const slideIn = keyframes({
  from: { transform: `translateX(calc(100% + ${VIEWPORT_PADDING}px))` },
  to: { transform: 'translateX(0)' },
});

const swipeOut = keyframes({
  from: { transform: 'translateX(var(--radix-toast-swipe-end-x))' },
  to: { transform: `translateX(calc(100% + ${VIEWPORT_PADDING}px))` },
});

/** Needs to be installed on our top-level app. */
export const WSToastProvider = styled(ToastPrimitive.Provider, { backgroundColor: 'green' });

/** Needs to wrap all pages where a toast can be displayed. */
export const WSToastViewport = styled(ToastPrimitive.Viewport, {
  position: 'fixed',
  bottom: 0,
  right: 0,
  display: 'flex',
  flexDirection: 'column',
  padding: VIEWPORT_PADDING,
  gap: 10,
  width: 484,
  maxWidth: '100vw',
  margin: 0,
  listStyle: 'none',
  zIndex: '$toasts',
  outline: 'none',
});

const StyledToast = styled(ToastPrimitive.Root, {
  position: 'relative',
  backgroundColor: 'white',
  borderRadius: '4px',
  boxShadow: '$wsDropShadow',
  padding: '24px',
  display: 'grid',
  gridTemplateAreas: '"title action" "description action"',
  gridTemplateColumns: 'auto max-content',
  columnGap: 15,
  alignItems: 'center',
  borderLeft: '8px solid $gray6',

  '& .toast-close-button': {
    visibility: 'hidden',
  },

  '&:hover': {
    '& .toast-close-button': {
      visibility: 'visible',
    },
  },

  '@media (prefers-reduced-motion: no-preference)': {
    '&[data-state="open"]': {
      animation: `${slideIn} 150ms cubic-bezier(0.16, 1, 0.3, 1)`,
    },
    '&[data-state="closed"]': {
      animation: `${hide} 100ms ease-in`,
    },
    '&[data-swipe="move"]': {
      transform: 'translateX(var(--radix-toast-swipe-move-x))',
    },
    '&[data-swipe="cancel"]': {
      transform: 'translateX(0)',
      transition: 'transform 200ms ease-out',
    },
    '&[data-swipe="end"]': {
      animation: `${swipeOut} 100ms ease-out`,
    },
  },

  variants: {
    severity: {
      success: {
        borderLeftColor: '$wsGreen',
      },
      warning: {
        borderLeftColor: '$wsOrange',
      },
      failure: {
        borderLeftColor: '$wsRed',
      },
      info: {
        borderLeftColor: '$gray6',
      },
    },
  },
});

const StyledTitle = styled(ToastPrimitive.Title, {
  gridArea: 'title',
  marginBottom: 5,
  fontSize: '$h3',
  fontWeight: '$semiBold',
  lineHeight: '150%',
  color: '$gray8',
});

const StyledDescription = styled(ToastPrimitive.Description, {
  gridArea: 'description',
  margin: 0,
  fontSize: '$bodyMedium',
  fontWeight: '$light',
  lineHeight: '150%',
  color: '$gray6',
});

const StyledToastClose = styled(ToastPrimitive.Close, {
  position: 'absolute',
  // Inset + padding should equal the card's padding.
  top: '12px',
  right: '12px',
  padding: '12px',

  border: 'none',
  backgroundColor: 'transparent',
  alignContent: 'center',
  verticalAlign: 'middle',
  borderRadius: '$round',

  cursor: 'pointer',
  transition: '$wsHoverTransition',

  '&:hover': { backgroundColor: '$primaryWithAlpha' },
  '&:focus': { boxShadow: `0 0 0 2px $primaryWithAlpha` },
});
