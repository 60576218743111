import { UUID } from '../lib/client/v1/entities';
import { isExperimentEnabled } from './flags';
import { UserInfo } from './types';

const WS_USER_TO_IMPERSONATE_LS_KEY = 'ws-impersonation-target';

export function getStoredUserToImpersonateId(): UUID | '' {
  if (typeof window !== 'undefined') {
    return (localStorage.getItem(WS_USER_TO_IMPERSONATE_LS_KEY) as UUID) ?? '';
  }
  return '';
}

export function setStoredUserToImpersonateId(newValue: string): void {
  if (typeof window !== 'undefined') {
    localStorage.setItem(WS_USER_TO_IMPERSONATE_LS_KEY, newValue);
  }
}

export class ClientFlag {
  private value: boolean;

  constructor(
    readonly friendlyName: string,
    readonly devName: string,
    defaultValue: boolean,
  ) {
    const rawValue = typeof localStorage !== 'undefined' ? localStorage.getItem(this.devName) : defaultValue;
    if (rawValue === 'true') {
      this.value = true;
    } else if (rawValue === 'false') {
      this.value = false;
    } else {
      this.value = defaultValue;
    }
  }

  get(): boolean {
    return this.value;
  }

  getLocalStorageValue(): boolean {
    return this.get();
  }

  setLocalStorageValue(newValue: boolean): void {
    this.value = newValue;
    localStorage.setItem(this.devName, this.value ? 'true' : 'false');
  }
}

/** Flags that are stashed in local storage. Use `UserExperimentFlags` for anything used in production */
export const FLAGS = {
  SKIP_PAYWALL_FOR_LOCALHOST: new ClientFlag('Skip paywall on localhost', 'skip_paywall_locally', true),
  INVISIBLE_DEV_TOOLBOX: new ClientFlag('Invisible dev tools', 'invisibleDevToolbox', false),
  ALLOW_MAPPING_UNSUPPORTED_FIELDS: new ClientFlag('Allow mapping all fields', 'allowMappingUnsupportedFields', false),
  FORCE_ENABLE_RECORD_MATCHING: new ClientFlag('Force-enable record matching', 'forceEnableRecordMatching', false),
};

export function devToolsEnabled(user: UserInfo | null): boolean {
  return isExperimentEnabled('DUSKY_DEV_TOOLBOX', user);
}

/**
 * There is a checkbox in dev settings to make dev tools invisible, for people that are taking screenshots, but from
 * an account with dev tools permissions.
 * Any piece of UI should check this flag instead of `devToolsEnabled()`.
 */
export function devToolsEnabledAndVisible(user: UserInfo | null): boolean {
  return devToolsEnabled(user) && !FLAGS.INVISIBLE_DEV_TOOLBOX.get();
}
