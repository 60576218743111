import { PostHog } from 'posthog-js';

/**
 * An fake PostHogProvider that does nothing but consume the same paramters as the normal PostHogProvider and render
 * children. This exists so that Dusky goesn't generate console errors because an empty component does not
 * consume the 'client' prop.
 */
export const EmptyPosthogProvider = ({
  children,
}: {
  children: React.ReactNode;
  client?: PostHog | undefined;
}): JSX.Element => {
  return <>{children}</>;
};
