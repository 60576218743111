import { SVGWrapper, SVGWrapperProps } from '@/components/v2/Icons/SvgWrapper';
import { getIconSize } from '@/components/v2/Icons/utils';

export const ShoppingCart = (props: SVGWrapperProps): JSX.Element => {
  const iconSize = getIconSize(props?.size || 'sm');
  return (
    <SVGWrapper {...props}>
      <g clipPath="url(#clip0_39_27213)">
        <path d="M2.91659 8.16669H10.5791C11.1699 8.16669 11.4653 8.16669 11.7005 8.05653C11.9076 7.9595 12.0817 7.80371 12.201 7.60853C12.3365 7.38696 12.3691 7.09335 12.4343 6.50616L12.7756 3.43489C12.7955 3.25553 12.8055 3.16586 12.7766 3.09645C12.7513 3.03549 12.7061 2.98492 12.6483 2.95301C12.5825 2.91669 12.4922 2.91669 12.3118 2.91669H2.62492M1.1665 1.16669H1.89476C2.04912 1.16669 2.12631 1.16669 2.18669 1.19604C2.23985 1.22189 2.2839 1.26327 2.31301 1.31471C2.34607 1.37315 2.35089 1.45018 2.36052 1.60424L2.88916 10.0625C2.89879 10.2165 2.9036 10.2936 2.93667 10.352C2.96577 10.4034 3.00983 10.4448 3.06299 10.4707C3.12337 10.5 3.20055 10.5 3.35491 10.5H11.0832M4.37484 12.5417H4.38067M9.62484 12.5417H9.63067M4.6665 12.5417C4.6665 12.7028 4.53592 12.8334 4.37484 12.8334C4.21375 12.8334 4.08317 12.7028 4.08317 12.5417C4.08317 12.3806 4.21375 12.25 4.37484 12.25C4.53592 12.25 4.6665 12.3806 4.6665 12.5417ZM9.9165 12.5417C9.9165 12.7028 9.78592 12.8334 9.62484 12.8334C9.46375 12.8334 9.33317 12.7028 9.33317 12.5417C9.33317 12.3806 9.46375 12.25 9.62484 12.25C9.78592 12.25 9.9165 12.3806 9.9165 12.5417Z" />
      </g>
      <defs>
        <clipPath id="clip0_39_27213">
          <rect width={iconSize} height={iconSize} fill="white" />
        </clipPath>
      </defs>
    </SVGWrapper>
  );
};
