// Central location to store flags.

import { UserExperimentFlags } from '../lib/client/v1/entities';
import { UserInfo } from './types';

export enum Flavor {
  Local = 'local',
  Test = 'test',
  Staging = 'staging',
  Production = 'production',
}

export function getBuildFlavor(): Flavor {
  // The flavor comes from a property specified in the docker file.
  const flavor = process.env.NEXT_PUBLIC_WS_APP_ENV;
  switch (flavor) {
    case 'production':
      return Flavor.Production;
    case 'staging':
      return Flavor.Staging;
    case 'test':
      return Flavor.Test;
    case 'local':
      return Flavor.Local;
    default:
      return Flavor.Local;
  }
}

export function getBuildVersion(): string {
  return process.env.NEXT_PUBLIC_WS_BUILD_VERSION ?? '00.00.00.00.00.DEV';
}

export function getFrontEndBaseUrl(flavor: Flavor): string {
  switch (flavor) {
    case Flavor.Production:
      return 'https://app.whalesync.com';
    case Flavor.Staging:
      return 'https://staging.whalesync.com';
    case Flavor.Test:
      return 'https://test.whalesync.com';
    case Flavor.Local:
      return 'http://localhost:3030';
  }
}

export function isExperimentEnabled(experiment: keyof UserExperimentFlags, user: UserInfo | null): boolean {
  return user?.whalesyncUser.experimentFlags[experiment] === true;
}

export const IS_V2_UI = process.env.NEXT_PUBLIC_UI_TYPE === 'v2';
