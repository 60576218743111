import { Switch as MantineSwitch } from '@mantine/core';

import styles from './Switch.module.css';

export const Switch = MantineSwitch.extend({
  vars: (theme, props) => {
    if (props.size === 'sm') {
      return {
        root: {
          '--switch-height': '16px',
          '--switch-width': '28px',
          '--switch-thumb-size': '12px',
          '--switch-label-font-size': 'var(--mantine-font-size-sm)',
        },
      };
    }

    return { root: {} };
  },
  classNames: (theme, props) => {
    if (props.size === 'sm') {
      return {
        root: styles.root,
        track: styles.track,
        thumb: styles.thumb,
        input: styles.input,
      };
    }

    return {};
  },
  defaultProps: {
    size: 'sm',
    color: 'blue',
  },
});
