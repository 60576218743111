import { Avatar as MantineAvatar } from '@mantine/core';

import styles from './Avatar.module.css';

export const Avatar = MantineAvatar.extend({
  vars: (theme, props) => {
    return {
      root: {
        '--avatar-radius': `var(--mantine-radius-${props.size || 'md'})`,
      },
    };
  },
  classNames: {
    root: styles.root,
    placeholder: styles.placeholder,
  },
});
