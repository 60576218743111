import { Button as MantineButton } from '@mantine/core';
import cx from 'clsx';

import styles from './Button.module.css';

export const Button = MantineButton.extend({
  vars: (theme, props) => {
    if (props.size === 'sm') {
      return {
        root: {
          '--button-radius': 'var(--mantine-radius-md)',
          '--button-padding-x': 'var(--mantine-spacing-xs)',
        },
      };
    }

    if (props.size === 'compact-sm') {
      return {
        root: {
          '--button-radius': 'var(--mantine-radius-sm)',
          '--button-padding-x': 'var(--mantine-spacing-2xs)',
        },
      };
    }

    return { root: {} };
  },
  classNames: (theme, props) => {
    return {
      root: cx(styles.root, styles[`root-${props.color}`]),
      section: styles.section,
    };
  },
  defaultProps: {
    size: 'sm',
    color: 'gray',
  },
});
