import { styled } from 'stitches.config';

const WSText = styled('span', {
  fontWeight: '$medium',
  lineHeight: '$4',
  display: 'block',
  variants: {
    variant: {
      h1: {
        fontSize: '$h1',
        fontWeight: '$semiBold',
        lineHeight: '125%',
        color: '$gray9',
      },
      h2: {
        fontSize: '$h2',
        fontWeight: '$semiBold',
        lineHeight: '125%',
        color: '$gray9',
      },
      h3: {
        fontSize: '$h3',
        fontWeight: '$semiBold',
        lineHeight: '150%',
        color: '$gray8',
      },
      h4: {
        fontSize: '$h4',
        fontWeight: '$semiBold',
        lineHeight: '125%',
        color: '$gray7',
      },
      h5: {
        fontSize: '$h5',
        fontWeight: '$semiBold',
        lineHeight: '125%',
        color: '$gray6',
      },
      bodySmall: {
        fontSize: '$bodySmall',
        fontWeight: '$light',
        lineHeight: '125%',
        color: '$gray6',
      },
      body: {
        fontSize: '$bodyMedium',
        fontWeight: '$light',
        lineHeight: '150%',
        color: '$gray6',
      },
      bodyLarge: {
        fontSize: '$bodyLarge',
        fontWeight: '$light',
        lineHeight: '150%',
        color: '$gray6',
      },
    },
    medium: {
      true: {
        fontWeight: '$medium',
      },
    },
    semiBold: {
      true: {
        fontWeight: '$semiBold',
      },
    },
    bold: {
      true: {
        fontWeight: '$bold',
      },
    },
    light: {
      true: {
        fontWeight: '$light',
      },
    },
  },
});

export default WSText;
