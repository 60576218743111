import { Tooltip as MantineTooltip } from '@mantine/core';

export const Tooltip = MantineTooltip.extend({
  styles(theme, props) {
    if (props.color === 'gray') {
      return {
        tooltip: {
          background: 'var(--mantine-color-gray-11)',
        },
      };
    }

    return {};
  },
  defaultProps: {
    color: 'gray',
    position: 'top',
  },
});
