import { Pagination as MantinePagination } from '@mantine/core';
import styles from './Pagination.module.css';

export const Pagination = MantinePagination.extend({
  vars() {
    return {
      root: {
        '--pagination-active-bg': 'transparent',
        '--pagination-active-color': 'var(--mantine-color-gray-11)',
        '--pagination-control-radius': 'var(--mantine-radius-md)',
        '--pagination-control-size': '32px',
        '--pagination-control-fz': '14px',
      },
    };
  },
  classNames() {
    return {
      control: styles.control,
    };
  },
  defaultProps: {
    size: 'sm',
  },
});
