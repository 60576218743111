import { ActionIcon as MantineActionIcon } from '@mantine/core';
import cx from 'clsx';

import buttonStyles from '../Button/Button.module.css';
import styles from './ActionIcon.module.css';

export const ActionIcon = MantineActionIcon.extend({
  classNames: (theme, props) => {
    return { root: cx(buttonStyles[`root-${props.color}`], styles.root) };
  },
  defaultProps: {
    variant: 'outline',
    color: 'gray',
    size: 'sm',
  },
});
