import { styled } from 'stitches.config';

const WSButton = styled('button', {
  all: 'unset',
  display: 'flex',
  alignItems: 'center',
  boxSizing: 'border-box',
  userSelect: 'none',
  textAlign: 'center',
  padding: '8px 16px',
  width: 'fit-content',
  cursor: 'pointer',
  transition: '$wsHoverTransition',

  '&::before': {
    boxSizing: 'border-box',
  },
  '&::after': {
    boxSizing: 'border-box',
  },
  borderRadius: '8px',
  fontWeight: '$medium',
  lineHeight: '150%',

  variants: {
    variant: {
      default: {
        backgroundColor: '$primary',
        border: '1px solid rgba(0, 0, 0, 0.08)',
        color: 'white',
        '&:hover': {
          opacity: 0.8,
        },
        '&:active': {
          opacity: 0.6,
        },
        '&:focus': {},
      },
      secondary: {
        backgroundColor: '$wsRed',
        color: 'white',
        '&:hover': {
          opacity: 0.8,
        },
        '&:active': {
          opacity: 0.6,
        },
        '&:focus': {},
      },
      ghost: {
        backgroundColor: 'transparent',
        color: '$gray7',
        '&:hover': {
          backgroundColor: '$gray1',
          boxShadow: 'inset 0 0 0 1px $colors$gray4',
        },
        '&:active': {
          backgroundColor: '$gray2',
        },
        '&:focus': {},
      },
      onlyContent: {
        margin: 0,
        padding: 2,
        '&:hover': {
          color: '$primary',
        },
        '&:active': {},
        '&:focus': {},
      },
      outlined: {
        border: '2px solid $primary',
        color: '$accent6',
        backgroundColor: 'white',
        '&:hover': {
          '&:hover': {
            backgroundColor: '$primary',
            color: 'white',
          },
        },
      },
      quiet: {
        color: '$gray7',
        backgroundColor: '$gray2',
        '&:hover': {
          opacity: 0.8,
        },
      },
    },
    fullWidth: {
      true: {
        width: '100%',
      },
    },
    outlined: {
      true: {
        border: '1px solid $gray2',
      },
    },
    disabled: {
      true: {
        pointerEvents: 'none',
        color: '$gray3',
        backgroundColor: '$gray1',
        '&:hover': {},
        '&:active': {},
        '&:focus': {},
      },
    },
    selected: {
      true: {
        // Depends on variant, so styles are defined in the combined variants.
        // Set something default, but please override it in the compoundVariants section.
        backgroundColor: '$accent7',
      },
      false: {},
    },
    size: {
      iconButton: {
        padding: '6px 6px',
        lineHeight: '1',
      },
      small: {
        fontSize: '$h5',
        padding: '8px 16px',
        borderRadius: '8px',
        height: '32px',
        gap: '4px',
      },
      default: {
        fontSize: '$h4',
        padding: '8px 18px',
        borderRadius: '10px',
        height: '40px',
        gap: '8px',
      },
      sidebar: {
        fontSize: '$h5',
        padding: '8px 14px',
        borderRadius: '12px',
        gap: '8px',
      },
    },
  },
  // Any special handling for when variants are combined and need tweaks.
  compoundVariants: [
    {
      variant: 'ghost',
      disabled: true,
      css: {
        color: '$gray7',
      },
    },
    {
      variant: 'ghost',
      selected: 'true',
      css: {
        backgroundColor: '$primary',
        color: 'white',
        '&:hover': {
          color: '$primary',
        },
      },
    },
    {
      variant: 'onlyContent',
      size: 'default',
      css: {
        padding: 0,
      },
    },
  ],
  defaultVariants: {
    variant: 'default',
    size: 'default',
    selected: 'false',
  },
});
export default WSButton;
