export const getCapitalizedFirstLetters = (sentence: string): string => {
  const words = sentence.split(/\W+/);
  const firstLetterCapitalizedWords = words.map((e) => e.charAt(0).toUpperCase());
  return firstLetterCapitalizedWords.join('');
};

/**
 * Return the enum value in string enum `T` named `strVal`, or `defaultValue` if it isn't recognized.
 * Accepts values of `strVal` that are either case name ('FOO') OR case value ('foo').
 */
export function stringToEnum<T extends object, DefaultType>(
  strVal: string,
  type: T,
  defaultValue: DefaultType,
): T[keyof T] | DefaultType {
  for (const [k, v] of Object.entries(type)) {
    if (strVal === k || strVal === v) {
      return type[k as keyof T];
    }
  }
  return defaultValue;
}

export function isNotEmpty<TValue>(value: TValue | null | undefined): value is TValue {
  return value !== null && value !== undefined;
}

export function assertNotEmpty<T>(value: NonNullable<T> | null | undefined, msg?: string): NonNullable<T> {
  if (isNotEmpty(value)) {
    return value;
  }
  throw new Error(`Value unexpectedly empty ${msg ? ': ' + msg : ''}`);
}

// eslint-disable-next-line @typescript-eslint/no-unused-vars
export function assertUnreachable(_: never): never {
  throw new Error("Didn't expect to get here");
}

export function compareIgnoringCase(str1: string, str2: string): boolean {
  return str1.localeCompare(str2, undefined, { sensitivity: 'accent' }) === 0;
}

export async function runForAtLeastOneSecond<T>(func: () => Promise<T>): Promise<T> {
  const startTime = Date.now();
  const result = await func();
  const endTime = Date.now();
  const timeSpent = endTime - startTime;
  if (timeSpent < 1000) {
    await new Promise<void>((resolve) => setTimeout(() => resolve(), 1000 - timeSpent));
  }
  return result;
}

export function capitalizeFirstLetter(str: string): string {
  return str.charAt(0).toUpperCase() + str.slice(1);
}

export function intRange(start: number, length: number): number[] {
  return Array(length)
    .fill(0)
    .map((_, i) => i + start);
}

export function getLastElement<T>(arr: T[]): T | undefined {
  const len = arr.length;
  if (arr.length > 0) {
    return arr[len - 1];
  }
}

export function isStringArray(o: unknown): o is string[] {
  if (!Array.isArray(o)) {
    return false;
  }
  for (const e of o) {
    if (typeof e !== 'string') {
      return false;
    }
  }
  return true;
}

export function copyWithModifiedMatchingItem<T>(
  arr: T[],
  predicate: (item: T) => boolean,
  /** NOTE: Make sure you copy it if it's an object and you're modifying it! */
  modifier: (item: T) => T,
): T[] {
  const index = arr.findIndex(predicate);
  if (index === -1) {
    return arr;
  }
  const newArr = [...arr];
  newArr[index] = modifier(newArr[index]);
  return newArr;
}

export function copyWithReplacedMatchingItem<T>(arr: T[], predicate: (item: T) => boolean, newItem: T): T[] {
  return copyWithModifiedMatchingItem(arr, predicate, () => newItem);
}

export function isNullOrUndefined(val: unknown): boolean {
  return val === undefined || val === null;
}
