import { SVGWrapper, SVGWrapperProps } from '@/components/v2/Icons/SvgWrapper';

export const HorizontalMore = (props: SVGWrapperProps): JSX.Element => {
  return (
    <SVGWrapper {...props}>
      <path d="M7.00016 7.58335C7.32233 7.58335 7.5835 7.32219 7.5835 7.00002C7.5835 6.67785 7.32233 6.41669 7.00016 6.41669C6.678 6.41669 6.41683 6.67785 6.41683 7.00002C6.41683 7.32219 6.678 7.58335 7.00016 7.58335Z" />
      <path d="M11.0835 7.58335C11.4057 7.58335 11.6668 7.32219 11.6668 7.00002C11.6668 6.67785 11.4057 6.41669 11.0835 6.41669C10.7613 6.41669 10.5002 6.67785 10.5002 7.00002C10.5002 7.32219 10.7613 7.58335 11.0835 7.58335Z" />
      <path d="M2.91683 7.58335C3.239 7.58335 3.50016 7.32219 3.50016 7.00002C3.50016 6.67785 3.239 6.41669 2.91683 6.41669C2.59466 6.41669 2.3335 6.67785 2.3335 7.00002C2.3335 7.32219 2.59466 7.58335 2.91683 7.58335Z" />
    </SVGWrapper>
  );
};
