import { Loader } from '@mantine/core';
import { useEffect, useState } from 'react';
import { CSS } from '../../stitches.config';
import { WSFlex } from '../base-widgets/ws-flex';

type LoaderProps = {
  /** The size in numbers is in rem */
  size?: number | 'xs' | 'sm' | 'md' | 'lg' | 'xl';
  color?: string;
  variant?: 'bars' | 'oval' | 'dots';
  css?: CSS;
};

/** Convenience wrapper in a fullWidth/fullHeight container that centers */
export function MantineLoaderCentered(props: LoaderProps): JSX.Element {
  return (
    <WSFlex css={props.css} fullHeight fullWidth justify={'center'} align={'center'}>
      {/* We use lg as default since most places will use this size */}
      <Loader {...props} size={props.size ?? 'lg'} />
    </WSFlex>
  );
}

/**
 * Usually loading happens quite quickly, so it's just flicky jank to show the loader.
 * This waits 750ms before showing it, so most of the time it won't be visible and the user doesn't see the UI jump
 * around.
 */
export function LoaderWithDelay(): JSX.Element {
  const DELAY_MS = 750;

  const [showLoader, setShowLoader] = useState(false);

  useEffect(() => {
    const timer = setTimeout(() => setShowLoader(true), DELAY_MS);
    return () => clearTimeout(timer);
  });

  return showLoader ? <Loader size="lg" /> : <></>;
}
