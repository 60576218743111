import * as TooltipPrimitive from '@radix-ui/react-tooltip';
import { keyframes, styled } from 'stitches.config';

const slideUpAndFade = keyframes({
  '0%': { opacity: 0, transform: 'translateY(2px)' },
  '100%': { opacity: 1, transform: 'translateY(0)' },
});

const slideRightAndFade = keyframes({
  '0%': { opacity: 0, transform: 'translateX(-2px)' },
  '100%': { opacity: 1, transform: 'translateX(0)' },
});

const slideDownAndFade = keyframes({
  '0%': { opacity: 0, transform: 'translateY(-2px)' },
  '100%': { opacity: 1, transform: 'translateY(0)' },
});

const slideLeftAndFade = keyframes({
  '0%': { opacity: 0, transform: 'translateX(2px)' },
  '100%': { opacity: 1, transform: 'translateX(0)' },
});

const StyledContent = styled(TooltipPrimitive.Content, {
  borderRadius: '8px',
  padding: '12px 16px',
  maxWidth: '400px',
  fontSize: 14,
  lineHeight: 1.25,
  fontWeight: '$semiBold',
  backgroundColor: '$gray7',
  color: 'white',
  boxShadow: '$wsDropShadow',
  '@media (prefers-reduced-motion: no-preference)': {
    animationDuration: '400ms',
    animationTimingFunction: 'cubic-bezier(0.16, 1, 0.3, 1)',
    animationFillMode: 'forwards',
    willChange: 'transform, opacity',
    '&[data-state="delayed-open"]': {
      '&[data-side="top"]': { animationName: slideDownAndFade },
      '&[data-side="right"]': { animationName: slideLeftAndFade },
      '&[data-side="bottom"]': { animationName: slideUpAndFade },
      '&[data-side="left"]': { animationName: slideRightAndFade },
    },
  },
});

const StyledArrow = styled(TooltipPrimitive.Arrow, {
  fill: '$gray7',
  marginLeft: '7px', // Half width of the error icon minus half width of the arrow, to align their midpoints
});

// Exports
export const WSTooltipProvider = TooltipPrimitive.Provider;
/**
 * Set delayDuration to e.g. 0 or 400, depending on desired speed of tooltip appearance. The default of 700 is pretty slow.
 */
export const WSTooltip = TooltipPrimitive.Root;
export const WSTooltipTrigger = TooltipPrimitive.Trigger;
export const WSTooltipContent = StyledContent;
export const WSTooltipArrow = StyledArrow;
export const WSTooltipPortal = TooltipPrimitive.Portal;
