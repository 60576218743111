import { MantineProvider as BaseMantineProvider, createTheme } from '@mantine/core';

import { GeistSans } from 'geist/font/sans';
import { PropsWithChildren } from 'react';

import { ActionIcon } from '../ActionIcon';
import { AppShell } from '../AppShell';
import { Avatar } from '../Avatar';
import { Button } from '../Button';
import { Combobox } from '../Combobox';
import { Input } from '../Input';
import { Menu } from '../Menu';
import { Modal } from '../Modal';
import { Pagination } from '../Pagination';
import { Select } from '../Select';
import { Stepper } from '../Stepper';
import { Switch } from '../Switch';
import { Table } from '../Table';
import { Tabs } from '../Tabs';
import { Text } from '../Text';
import { TextInput } from '../TextInput';
import { ThemeIcon } from '../ThemeIcon';
import { Tooltip } from '../Tooltip';
import { variantColorResolver } from './variantColorResolver';

export const MANTINE_THEME = createTheme({
  cursorType: 'pointer',
  colors: {
    gray: [
      '#fafafa',
      '#f7f7f7',
      '#f0f0f0',
      '#ebebeb',
      '#e6e6e6',
      '#e8e8e8',
      '#d6d6d6',
      '#c7c7c7',
      '#8d8d8d',
      '#838383',
      '#646464',
      '#202020',
    ],
    'gray-a': [
      'rgba(0, 0, 0, 0.02)',
      'rgba(0, 0, 0, 0.04)',
      'rgba(0, 0, 0, 0.06)',
      'rgba(0, 0, 0, 0.08)',
      'rgba(0, 0, 0, 0.1)',
      'rgba(0, 0, 0, 0.11)',
      'rgba(0, 0, 0, 0.16)',
      'rgba(0, 0, 0, 0.22)',
      'rgba(0, 0, 0, 0.45)',
      'rgba(0, 0, 0, 0.49)',
      'rgba(0, 0, 0, 0.61)',
      'rgba(0, 0, 0, 0.87)',
    ],
    blue: [
      '#fafdff',
      '#f3fafe',
      '#e4f4ff',
      '#d2eeff',
      '#bfe5ff',
      '#a9d8f9',
      '#8cc8f1',
      '#5bb1e8',
      '#05a1e9',
      '#0295d8',
      '#0079bb',
      '#073954',
    ],
    'blue-a': [
      'rgba(0, 153, 255, 0.02)',
      'rgba(0, 149, 234, 0.05)',
      'rgba(0, 152, 255, 0.11)',
      'rgba(0, 159, 255, 0.18)',
      'rgba(0, 152, 255, 0.25)',
      'rgba(0, 140, 238, 0.34)',
      'rgba(0, 134, 224, 0.45)',
      'rgba(0, 134, 220, 0.64)',
      'rgba(0, 159, 233, 0.98)',
      'rgba(0, 148, 216, 0.99)',
      '#0079bb',
      'rgba(0, 51, 79, 0.97)',
    ],
    red: [
      '#fffcfc',
      '#fff7f6',
      '#ffebe9',
      '#ffdcd7',
      '#ffccc6',
      '#ffbcb5',
      '#f8a8a0',
      '#f08c84',
      '#ef4444',
      '#e13237',
      '#d3232c',
      '#661717',
    ],
    'red-a': [
      'rgba(255, 0, 0, 0.01)',
      'rgba(255, 29, 0, 0.04)',
      'rgba(255, 24, 0, 0.09)',
      'rgba(255, 32, 0, 0.16)',
      'rgba(255, 27, 0, 0.22)',
      'rgba(255, 25, 1, 0.29)',
      'rgba(237, 22, 0, 0.37)',
      'rgba(224, 17, 1, 0.48)',
      'rgba(233, 0, 0, 0.73)',
      'rgba(218, 0, 6, 0.8)',
      'rgba(204, 0, 11, 0.86)',
      'rgba(87, 0, 0, 0.91)',
    ],
    yellow: [
      '#fefdfb',
      '#fff8ed',
      '#fff1de',
      '#ffe6c6',
      '#ffdaaa',
      '#ffca83',
      '#ffb453',
      '#f19a00',
      '#f59e0b',
      '#e99300',
      '#a26a00',
      '#4f3515',
    ],
    'yellow-a': [
      'rgba(192, 128, 0, 0.02)',
      'rgba(255, 156, 0, 0.07)',
      'rgba(255, 147, 0, 0.13)',
      'rgba(255, 144, 0, 0.22)',
      'rgba(255, 144, 0, 0.33)',
      'rgba(255, 147, 0, 0.49)',
      'rgba(255, 144, 0, 0.67)',
      '#f19a00',
      'rgba(245, 154, 0, 0.96)',
      '#e99300',
      '#a26a00',
      'rgba(63, 35, 0, 0.92)',
    ],
    green: [
      '#fafffa',
      '#f3fcf4',
      '#e3f9e6',
      '#d1f4d6',
      '#baeec3',
      '#9ee4ab',
      '#76d68b',
      '#1ec35c',
      '#22c55e',
      '#00b953',
      '#00842b',
      '#0e3f1d',
    ],
    'green-a': [
      'rgba(0, 255, 0, 0.02)',
      'rgba(0, 192, 22, 0.05)',
      'rgba(0, 201, 28, 0.11)',
      'rgba(0, 195, 28, 0.18)',
      'rgba(0, 193, 34, 0.27)',
      'rgba(0, 185, 35, 0.38)',
      'rgba(0, 179, 40, 0.54)',
      'rgba(0, 187, 70, 0.88)',
      'rgba(0, 188, 69, 0.87)',
      '#00b953',
      '#00842b',
      'rgba(0, 52, 16, 0.95)',
    ],
    'gray-dark': [
      '#111111',
      '#191919',
      '#222222',
      '#2a2a2a',
      '#313131',
      '#3a3a3a',
      '#484848',
      '#606060',
      '#6e6e6e',
      '#7b7b7b',
      '#b3b3b3',
      '#eeeeee',
    ],
    'gray-dark-a': [
      '#000000',
      'rgba(0, 0, 0, 0.04)',
      'rgba(0, 0, 0, 0.07)',
      'rgba(0, 0, 0, 0.11)',
      'rgba(0, 0, 0, 0.13)',
      'rgba(0, 0, 0, 0.17)',
      'rgba(0, 0, 0, 0.23)',
      'rgba(0, 0, 0, 0.33)',
      'rgba(0, 0, 0, 0.39)',
      'rgba(0, 0, 0, 0.45)',
      'rgba(0, 0, 0, 0.68)',
      'rgba(0, 0, 0, 0.93)',
    ],
    purple: [
      '#fdfcff',
      '#f9f8ff',
      '#f2efff',
      '#e9e4ff',
      '#dfd8ff',
      '#d3c8ff',
      '#c1b2ff',
      '#ac95fb',
      '#8b5cf6',
      '#7f4ee7',
      '#6f43cd',
      '#371e6b',
    ],
    'purple-a': [
      'rgba(85, 0, 255, 0.01)',
      'rgba(37, 0, 255, 0.03)',
      'rgba(48, 0, 255, 0.06)',
      'rgba(48, 0, 255, 0.11)',
      'rgba(46, 0, 255, 0.15)',
      'rgba(51, 0, 255, 0.22)',
      'rgba(50, 0, 255, 0.3)',
      'rgba(56, 1, 246, 0.42)',
      'rgba(74, 0, 241, 0.64)',
      'rgba(71, 0, 221, 0.69)',
      'rgba(60, 0, 187, 0.74)',
      'rgba(28, 0, 87, 0.88)',
    ],
  },
  primaryColor: 'blue',
  primaryShade: 8,
  fontFamily: GeistSans.style.fontFamily,

  fontSizes: {
    xs: '13px',
    sm: '14px',
    md: '16px',
  },

  // TODO: In design we dont have variables for spacing,
  // so taking a base spaces and adding it here
  spacing: {
    '2xs': '4px',
    xs: '8px',
    sm: '12px',
    md: '16px',
    lg: '24px',
    xl: '32px',
  },

  radius: {
    xs: '4px',
    sm: '6px',
    md: '8px',
    lg: '10px',
    full: '100%',
  },
  defaultRadius: 'sm',

  // 🗒️ This function overrides the base properties using variant, size & color
  variantColorResolver,

  shadows: {
    sm: `0px 1px 2px -1px #0000000F`,
    md: `0px 1px 3px -1.5px #00000009, 0px 1px 2px -0.5px #00000009`,
    'md-inner': `0px 1px 1px 1px rgba(255, 255, 255, 0.2) inset,
      0px 1px 2px -0.5px var(--mantine-color-gray-a-2, rgba(0, 0, 0, 0.04)),
      0px 1px 3px -1.5px var(--mantine-color-gray-a-2, rgba(0, 0, 0, 0.04));`,
    'focus-visible': `0px 0px 0px 2px var(--mantine-color-blue-a-5),
      0px 1px 1px 0px rgba(255, 255, 255, 0.2) inset,
      0px 1px 2px -0.5px var(--mantine-color-gray-a-1, rgba(0, 0, 0, 0.04)),
      0px 1px 3px -1.5px var(--mantine-color-gray-a-1, rgba(0, 0, 0, 0.04))`,
    'error-focus-visible': `0px 0px 0px 2px var(--mantine-color-red-a-5),
      0px 1px 1px 0px rgba(255, 255, 255, 0.2) inset,
      0px 1px 2px -0.5px var(--mantine-color-gray-a-1, rgba(0, 0, 0, 0.04)),
      0px 1px 3px -1.5px var(--mantine-color-gray-a-1, rgba(0, 0, 0, 0.04))`,
  },

  // 🗒️ Customizing indiviual component with custom styles & default Props config
  components: {
    Button,
    TextInput,
    Select,
    Input,
    ActionIcon,
    Menu,
    AppShell,
    Avatar,
    Text,
    Switch,
    Modal,
    Combobox,
    Pagination,
    Table,
    Tabs,
    Tooltip,
    Stepper,
    ThemeIcon,
  },
});

// TODO: Only present for testing storybook, we can remove it once we make the shift to v1.5
export const MantineProvider = ({ children }: PropsWithChildren): JSX.Element => {
  return <BaseMantineProvider theme={MANTINE_THEME}>{children}</BaseMantineProvider>;
};
