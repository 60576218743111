import type * as Stitches from '@stitches/react';
import { styled } from 'stitches.config';

const WSBadge = styled('span', {
  // Reset
  alignItems: 'center',
  appearance: 'none',
  borderWidth: '0',
  borderRadius: '$pill',
  boxSizing: 'border-box',
  display: 'inline-flex',
  flexShrink: 0,
  fontFamily: 'inherit',
  justifyContent: 'center',
  lineHeight: '1',
  verticalAlign: 'middle',
  outline: 'none',
  padding: '8px',
  textDecoration: 'none',
  userSelect: 'none',
  width: 'fit-content',
  color: 'white',
  whiteSpace: 'nowrap',
  fontSize: '$bodyMedium',
  fontWeight: '$light',
  fontVariantNumeric: 'tabular-nums',

  variants: {
    size: {
      xsmall: {
        height: '10px',
        fontSize: '$bodySmall',
        fontWeight: '$semiBold',
      },
      small: {
        height: '20px',
      },
      medium: {
        height: '26px',
      },
      large: {
        height: '34px',
      },
    },
    variant: {
      green: {
        backgroundColor: '$wsGreen',
      },
      error: {
        backgroundColor: '$wsRed',
      },
      warning: {
        backgroundColor: '$wsOrangeVeryLight',
        color: '$wsOrange',
      },
      orange: {
        background: '$wsOrange',
        color: '$wsOrangeDark',
      },
      gray: {
        backgroundColor: '$gray5',
      },
      lightGray: {
        backgroundColor: '$gray1',
        color: '$gray6',
      },
      disabledGray: {
        background: '$gray1',
        color: '$gray4',
      },
      lightGreen: {
        backgroundColor: '$wsGreenVeryLight',
        color: '$wsGreen',
      },
      lightRed: {
        backgroundColor: '$wsRedVeryLight',
        color: '$wsRed',
      },
      accent: {
        backgroundColor: '$accent1',
        color: '$primary',
      },
      lightSecondary: {
        background: '$secondary1',
        color: '$secondary3',
      },
    },
  },

  defaultVariants: {
    size: 'small',
    variant: 'green',
  },
});

export default WSBadge;

export type WSBadgeVariant = Stitches.VariantProps<typeof WSBadge>['variant'];
