import '@/styles/ag-grid-custom.css';

import { ClerkProvider } from '@clerk/nextjs';
import { ColorSchemeScript, MantineProvider } from '@mantine/core';
import { Notifications } from '@mantine/notifications';

import '@mantine/core/styles.css';
import '@mantine/dates/styles.css';
import '@mantine/notifications/styles.css';
import TimeAgo from 'javascript-time-ago';
import en from 'javascript-time-ago/locale/en.json';
import type { AppProps } from 'next/app';
import React, { useEffect } from 'react';
import { WSToastProvider, WSToastPublisher, WSToastPublisherContext } from '../components/base-widgets/ws-toast';
import { WSTooltipProvider } from '../components/base-widgets/ws-tooltip';
import { ClerkAuthContextProvider } from '../components/contexts/clerk-auth';
import { startBugsnag } from '../lib/client/bugsnag';
import { startGTM } from '../lib/client/gtm';
import { PostHogEvents, captureEvent, initializePostHog } from '../lib/client/posthog';
import { MANTINE_THEME, MANTINE_THEME_CSS_VARIABLE_RESOLVER } from '../utils/mantine';

import { SubscriptionContainer } from '@/components/common/subscription-container';
import ErrorBoundary from '@/components/v2/common/ErrorBoundary';
import { MANTINE_THEME as NEW_MANTINE_THEME } from '@/components/v2/ui';
import { IS_V2_UI } from '@/utils/flags';

import { useRouter } from 'next/router';
import defaultPosthog from 'posthog-js';

// Inject global styles
if (process.env.NEXT_PUBLIC_UI_TYPE) {
  import('../components/v2/ui/styles/global.css');
} else {
  import('../styles/globals.css');
}

TimeAgo.addLocale(en);

const BugSnagErrorBoundary = startBugsnag();

const PostHogProviderComponent = initializePostHog();

function MyApp({ Component, pageProps }: AppProps): JSX.Element {
  const router = useRouter();

  // Google tag manager init
  useEffect(() => {
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const isGtmActive = startGTM();
  }, []);

  useEffect(() => {
    // Track page views in PostHog
    const handleRouteChange = (url: string): void => {
      captureEvent(PostHogEvents.PAGE_VIEW, { url });
    };

    router.events.on('routeChangeComplete', handleRouteChange);

    return () => {
      router.events.off('routeChangeComplete', handleRouteChange);
    };
  }, [router.events]);

  const toastContextRef = React.useRef<WSToastPublisher>(null);

  const mantineThemeProviders = IS_V2_UI
    ? {
        theme: NEW_MANTINE_THEME,
      }
    : {
        theme: MANTINE_THEME,
        cssVariablesResolver: MANTINE_THEME_CSS_VARIABLE_RESOLVER,
      };

  return (
    <BugSnagErrorBoundary>
      <ColorSchemeScript defaultColorScheme="light" />
      <MantineProvider {...mantineThemeProviders}>
        <ErrorBoundary>
          {IS_V2_UI && <Notifications />}
          <WSTooltipProvider>
            <WSToastProvider duration={10000}>
              <WSToastPublisherContext.Provider value={toastContextRef}>
                <ClerkProvider>
                  <ClerkAuthContextProvider>
                    <SubscriptionContainer>
                      <PostHogProviderComponent client={defaultPosthog}>
                        <Component {...pageProps} />
                      </PostHogProviderComponent>
                    </SubscriptionContainer>
                  </ClerkAuthContextProvider>
                </ClerkProvider>
              </WSToastPublisherContext.Provider>
            </WSToastProvider>
          </WSTooltipProvider>
        </ErrorBoundary>
        {/* Linter doesn't like jsx or global for some reason */}
        {/* eslint-disable-next-line react/no-unknown-property */}
        <style jsx global>{`
          html,
          body,
          body > div:first-child,
          div#__next,
          div#__next > div {
            min-height: 100vh;
          }
        `}</style>
      </MantineProvider>
    </BugSnagErrorBoundary>
  );
}

export default MyApp;
